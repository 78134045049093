export const adjective = {
	singular: "teacher",
	plural: "teachers",
};

export const verb = {
	singular: "teaching",
};

export const job = {
	singular: "session",
	plural: "sessions",
};
